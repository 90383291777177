/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { BusinessDetailsModel } from './models/request/business-details.model';
import Auth from '../../models/response/auth.model';
import mainBgImage from '../../assets/images/cover-123.jpeg';
import { useAppDispatch } from '../../store';
import {
  getBusinessDetails, editBusinessDetails, fetchPartnerDetails, fetchOwnerDetails, fetchProfileStatus, fetchIndustryTypeList, submitBusinessEmail, submitBusinessEmailOtp,
  submitBillingEmail, submitBillingEmailOtp
} from '../../store/actions';
import { FileUpload, InputTextArea, InputTextField, InputTextFieldWithButton } from '../../components';
import { getUserSearch } from '../../services/user.service';
import ownerBadge from "./../../assets/images/icons/access-owner-full.svg";
import partnerBadge from "./../../assets/images/icons/access-partner-full.svg";
import adminBadge from "./../../assets/images/icons/access-admin-full.svg";
import * as CONSTANTS from '../../constants';
import { MsisdnStatusEnums } from '../../enum';

type Props = {
  auth: Auth
}

const BusinessDetails = ({ auth }: Props) => {

  const dispatch = useAppDispatch();
  const [tenantData, setTenantData] = useState({
    tenantGroupId: ""
  });
  const [logoUploadSizeError, setLogoUploadSizeError] = useState("");
  const [logo, setLogo] = useState<any>([]);
  const [supportDoc, setSupportDoc] = useState<any>([]);
  const [contactDetailsChanged, setContactDetailsChanged] = useState(false);
  const [bankCode, setBankCode] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [businessDetailsChanged, setBusinessDetailsChanged] = useState(false);

  const [showEmailOtpField, setShowEmailOtpField] = useState(false);
  const [emailVerified, setEmailVerified] = useState<string | undefined>();
  const [remainingTime, setRemainingTime] = useState(0);
  const [emailOtp, setEmailOtp] = useState({ pin: "", emailOtpRef: "", emailtype: "business" });

  const [showBillingEmailOtpField, setShowBillingEmailOtpField] = useState(false);
  const [billingEmailVerified, setBillingEmailOtpField] = useState<string | undefined>();
  const [billingEmailRemainingTime, setBillingEmailRemainingTime] = useState(0);
  const [billingEmailOtp, setBillingEmailOtp] = useState({ pin: "", emailOtpRef: "", emailtype: "billing" })

  const [businessDetailsData, setBusinessDetailsData] = useState({
    businessName: "",
    businessEmail: "",
    businessDescription: "",
    businessRegNumber: "",
    ownerNicNumber: "",
    industryType: "",
    businessAddressNumber: "",
    businessAddressStreet: "",
    businessAddressCity: "",
    businessAddressDistrict: "",
    logo: "",
    supportDoc: "",
    responsivePersonName: "",
    responsivePersonNumber: "",
    billingEmail: ""
  });

  const [ownerDetailsData, setOwnerDetailsData] = useState({
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerMobile: "",
    logo: "",
    supportDoc: "",
    ownerProfileImage: "",
    msisdnStatus: "",
  })

  const [maDetails, setMaDetails] = useState({
    expertise: "",
    experience: "",
    personal_description: "",
    specification: "",
    account_holder: "",
    account_number: "",
    role: "",
    employee_id: "",
    employee_email: ""
  });

  const [profileDetails, setProfileDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    emailStatus: "",
    msisdn: "",
    msisdnStatus: "",
    nic: ""
  });

  const [partnerDetailsData, setPartnerDetailsData] = useState<Object[]>([]);

  const [emailUnique, setEmailUnique] = useState<boolean>(true);

  const [districtList, setDistrictList] = useState<string[]>([])
  const [industryTypeList, setIndustryTypeList] = useState<string[]>([])

  const businessEditPath = window.location.pathname.split('/')[3];

  const [businessDetailValidator] = useState(new SimpleReactValidator({
    messages: {
      required: 'You can’t keep this as empty',
      max: ':Maximum 100 charachters are allowed.',
      email: 'This does not look like an email.',
    }, autoForceUpdate: this
  }))

  const [viewAll, setViewAll] = useState({
    isClicked: false,
    partnerId: ''
  });

  const [editDisabled, setEditDisabled] = useState(false);
  const { isEditDetailsClicked,
    enterpriseDetailsUpdateStatus,
    businessDetails,
    ownerDetails,
    partnerDetails
  } = useSelector<any, any>(({ settings }) => settings);

  const [getIsEditDetailsClicked, setSsEditDetailsClicked] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isEditDetailsClicked) {
      setSsEditDetailsClicked(true)
    }
  }, [isEditDetailsClicked])

  useEffect(() => {
    if (businessEditPath) {
      setSsEditDetailsClicked(true)
    } else {
      setSsEditDetailsClicked(false)
    }
  }, [businessEditPath])

  const {
    userDetails,
    emailData,
    emailOtpData,
    billingEmailData,
    billingEmailOtpData
  } = useSelector<any, any>(({ user }) => user);

  useEffect(() => {
    const groupId = (userDetails && userDetails.OwnedEnterprises[0]) ? JSON.stringify(userDetails.OwnedEnterprises[0].groupId) : "";
    setTenantData({
      ...tenantData,
      tenantGroupId: groupId
    })

  }, [userDetails]);

  useEffect(() => {
    if (emailOtpData && emailOtpData.status == 200) {
      setEmailVerified("verified")
      setShowEmailOtpField(false)
      forceUpdate();
    } else if (userDetails && userDetails.OwnedEnterprises[0].Enterprise.isEmailVerified) {
      setEmailVerified("verified")
      setShowEmailOtpField(false)
      forceUpdate();
    } else {
      setEmailVerified("notVerified")
    }
  }, [emailOtpData, userDetails])

  useEffect(() => {
    if (emailData) {
      setShowEmailOtpField(true)
      setEmailOtp({ pin: '', emailOtpRef: emailData.reference, emailtype: "business" })
      forceUpdate();
    }
  }, [emailData])

  useEffect(() => {
    if (billingEmailOtpData && billingEmailOtpData.status == 200) {
      setBillingEmailOtpField("verified");
      setShowBillingEmailOtpField(false);
      forceUpdate();
    } else if (userDetails && userDetails.OwnedEnterprises[0].Enterprise.isBillingEmailVerified) {
      setBillingEmailOtpField("verified");
      setShowBillingEmailOtpField(false);
    } else {
      setBillingEmailOtpField("notVerified");
    }
  }, [billingEmailOtpData, userDetails])

  useEffect(() => {
    if (billingEmailData) {
      setShowBillingEmailOtpField(true);
      setBillingEmailOtp({ pin: '', emailOtpRef: billingEmailData.reference, emailtype: "billing" });
      forceUpdate();
    }
  }, [billingEmailData])

  useEffect(() => {
    let intervalId: any = null;

    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowEmailOtpField(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [remainingTime]);

  useEffect(() => {
    let intervalId: any = null;

    if (billingEmailRemainingTime > 0) {
      intervalId = setInterval(() => {
        setBillingEmailRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowBillingEmailOtpField(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [billingEmailRemainingTime]);

  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);

  }

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    if (tenantGroupId !== "") {
      dispatch<any>(getBusinessDetails(tenantGroupId));
      dispatch<any>(fetchOwnerDetails(tenantGroupId));
      dispatch<any>(fetchPartnerDetails(tenantGroupId));
    }
  }, [userDetails, getIsEditDetailsClicked, isSubmitted]);

  useEffect(() => {

    setDistrictList(["Colombo", "Kalutara", "Gampaha", "Ampara", "Anuradhapura", "Jaffna", "Kilinochchi", "Mannar", "Mullaitivu", "Vavuniya",
      "Puttalam", "Kurunegala", "Trincomalee", "Batticaloa", "Badulla", "Monaragala", "Hambantota", "Matara", "Galle", "Polonnaruwa",
      "Matale", "Kandy", "Nuwara Eliya", "Kegalle", "Ratnapura"]);

    setIndustryTypeList(["Apparel & Textiles", "Agriculture", "Banking & finance", "Construction", "Education", "Food &Manufacturing", "Healthcare", "IT & Technology", "Logistics", "Real Estate", "Retail", "Transport", "Tourism & Hospitality", "Other"]);
  }, [])

  const setBusinessDetails = (details: any) => {
    setBusinessDetailsData({
      ...businessDetailsData,
      businessName: details ? details.businessName : "",
      businessEmail: details ? details.businessEmail : "",
      businessDescription: details ? details.businessDescription : "",
      businessRegNumber: details ? details.bussinessRegNumber : "",
      ownerNicNumber: details ? details.ownerNicNumber : "",
      businessAddressNumber: details ? details.businessAddressNumber : "",
      businessAddressStreet: details ? details.businessAddressStreet : "",
      businessAddressCity: details ? details.businessAddressCity : "",
      businessAddressDistrict: details ? details.businessAddressDistrict : "",
      industryType: details ? details.industryType : "",
      logo: details ? details.logo : "",
      supportDoc: details ? details.supportDoc : "",
      billingEmail: details ? details.billingEmail : ""
    });
  };

  useEffect(() => {
    setBusinessDetails(businessDetails);
  }, [businessDetails, getIsEditDetailsClicked]);


  useEffect(() => {
    setOwnerDetailsData({
      ...ownerDetailsData,
      ownerFirstName: ownerDetails ? ownerDetails.ownerFirstName : "",
      ownerLastName: ownerDetails ? ownerDetails.ownerLastName : "",
      ownerEmail: ownerDetails ? ownerDetails.ownerEmail : "",
      ownerMobile: ownerDetails ? ownerDetails.ownerMobile : "",
      ownerProfileImage: (ownerDetailsData.ownerFirstName && ownerDetailsData.ownerLastName) ? (ownerDetailsData.ownerFirstName.charAt(0)).concat(ownerDetailsData.ownerLastName.charAt(0)).toUpperCase() : "",
      msisdnStatus: (ownerDetailsData.ownerMobile) ? "VERIFIED" : "PENDING",
    })
  }, [ownerDetails])

  const setMaDetail = (details: any) => {
    setMaDetails({
      ...maDetails,
      expertise: details ? details.expertise : "",
      experience: details ? details.yearsOfExperience : "",
      personal_description: details ? details.selfIntro : "",
      specification: details ? details.specification : "",
      account_holder: (Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].accountHolderName : "",
      account_number: (Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].accountNumber : "",
      role: userDetails.role,
      employee_id: details ? details.dialogEmpNo : "",
      employee_email: details ? details.email : "",
    })
  };

  useEffect(() => {
    if (userDetails) {
      setMaDetail(userDetails.Profile);
      setBankCode((Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].bankCode : "");
      setBranchCode((Array.isArray(userDetails.BankAccount) && userDetails.BankAccount.length > 0) ? userDetails.BankAccount[0].branchCode : "");
    }
  }, [userDetails])

  const onEditDetailsSave = (e: SyntheticEvent) => {

    businessDetailValidator.visibleFields = [];
    businessDetailValidator.hideMessages();

    const fmData = new FormData();
    fmData.append("businessEmail", businessDetailsData.businessEmail);
    fmData.append("businessDescription", businessDetailsData.businessDescription);
    fmData.append("businessAddressNumber", businessDetailsData.businessAddressNumber);
    fmData.append("businessAddressStreet", businessDetailsData.businessAddressStreet);
    fmData.append("businessAddressCity", businessDetailsData.businessAddressCity);
    fmData.append("businessAddressDistrict", businessDetailsData.businessAddressDistrict);
    fmData.append("billingEmail", businessDetailsData.billingEmail);

    if (Array.isArray(supportDoc) && supportDoc.length > 0) {
      fmData.append("supportDoc", (supportDoc[0].originFileObj));
    }

    if (emailUnique) {
      const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
      const enterpriseId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].Enterprise.uuid : "";

      if (businessDetailValidator.allValid()) {
        dispatch(editBusinessDetails(fmData, enterpriseId, tenantGroupId));
        getBusinessDetails(tenantGroupId);
        businessDetailValidator.visibleFields = [];
        forceUpdate()
      } else {
        businessDetailValidator.showMessages();
        forceUpdate()
      }
    }
  }


  const handleBusinessDetailsChange = (name: string) => (event: any) => {
    setBusinessDetailsData({ ...businessDetailsData, [name]: event.target.value });
    setBusinessDetailsChanged(true);
    if (event.target.name !== 'businessDescription') {
      setContactDetailsChanged(true);
    }
  };

  const renderDistrictList = () => {
    let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Not selected</option>]
    districtList.map((district, index) => {
      list.push(<option key={index} value={district}>{district}</option>)
    })
    return list
  }

  const renderIndustryList = () => {
    let list: JSX.Element[] = [<option key={'-1'} value="" disabled hidden>Not selected</option>]
    industryTypeList.map((industryType, index) => {
      list.push(<option key={index} value={industryType}>{industryType}</option>)
    })
    return list
  }

  const onBlurEmail = (e: any) => {
    let email = e.target.value;
    if (email !== "") {
      getUserSearch("email", email).then((response) => {
        setEmailUnique(response.data.data == '0' ? false : true);
      }).catch((error) => {
        console.log("error in business details blurEmail", error);
      });
    }
  }

  const onPressViewAll = (e: SyntheticEvent, partnerId: string) => {
    e.preventDefault();
    console.log("view all before:" + JSON.stringify(viewAll));
    setViewAll({
      ...viewAll,
      isClicked: true,
      partnerId: partnerId
    });
    console.log("view all:" + JSON.stringify(viewAll));
  }

  const onPressClose = () => {
    setViewAll({
      ...viewAll,
      isClicked: false,
      partnerId: ""
    });
  }

  const onChangeSupportDoc = (file: any) => {
    const fileSize = file.file.size / 1024 / 1024;
    const fileType = file.file.type;
    console.log("file type", fileType);

    if (fileSize > 5) {
      setLogoUploadSizeError("Filesize should be less than 5mb. Upload again.");
      businessDetailValidator.hideMessageFor('supportDoc');
    } else if (fileType !== "image/jpeg" && fileType !== "image/png" && fileType !== "application/pdf" && fileType !== "document/pdf") {
      setLogoUploadSizeError("Only png, jpg or pdf file types are allowed");
      businessDetailValidator.hideMessageFor('supportDoc');
    } else {
      setBusinessDetailsData({
        ...businessDetailsData,
        supportDoc: file // Update state with supportDoc
      });
      setLogoUploadSizeError("");
      setSupportDoc(file.fileList);
      businessDetailValidator.showMessageFor('supportDoc');
      forceUpdate();
    }
  }

  const onDropSupportDoc = () => {
    setSupportDoc([]);
    businessDetailValidator.showMessageFor('supportDoc');
    forceUpdate();
  }

  const beforeUploadSupportDoc = (file: any) => {
    return false;
  }

  const onChangeClose = () => {
    setContactDetailsChanged(false);
    setSsEditDetailsClicked(false);
  }

  const handleEmailOtpChange = (name: string) => (event: any) => {
    setEmailOtp({ ...emailOtp, pin: event.target.value });
  }

  const handleBillingEmailOtpChange = (name: string) => (event: any) => {
    setBillingEmailOtp({ ...billingEmailOtp, pin: event.target.value })
  }

  const verifyEmailForYourProfile = () => {
    setRemainingTime(300);
    if (businessDetailsData.businessEmail) {
      const req = {
        email: businessDetailsData.businessEmail,
        entUuid: userDetails?.OwnedEnterprises[0].Enterprise.uuid
      }
      dispatch(submitBusinessEmail(req))
    }
  }

  const validateEmailOtp = () => {
    console.log('inside validateEmailOtp = ', emailOtp);
    if (emailOtp.pin && emailOtp.emailOtpRef) {
      dispatch(submitBusinessEmailOtp(emailOtp))
    }
  }

  const verifyBillingEmailForYourProfile = () => {
    setBillingEmailRemainingTime(300);
    if (businessDetailsData.billingEmail) {
      const req = {
        email: businessDetailsData.billingEmail,
        entUuid: userDetails?.OwnedEnterprises[0].Enterprise.uuid
      }
      dispatch(submitBillingEmail(req))
    }
  }

  const validateBillingEmailOtp = () => {
    console.log('inside validateBillingEmailOtp = ', billingEmailOtp);
    if (billingEmailOtp.pin && billingEmailOtp.emailOtpRef) {
      dispatch(submitBillingEmailOtp(billingEmailOtp))
    }
  }

  return (
    <React.Fragment>
      <div className="sub-nav-inner-container__wrapper">
        <div className="sub-nav-inner-container content-holder">
          <div className="content-holder-header__wrapper">
            <div className="content-holder-header">
              <h4 className="inner-title">Business Details</h4>
              {getIsEditDetailsClicked && <button className="btn back-btn" onClick={onChangeClose}>✕&nbsp;Close </button>}
            </div>
          </div>
          {/* business details preview - step 1 */}
          {!getIsEditDetailsClicked &&
            <div className="card panel content-holder-panels">
              <div className="top-enterprise-details__wrapper">
                <div className="top-enterprise-details" style={{ backgroundImage: `url(${mainBgImage})` }}>
                  <div className="profile-placeholder__wrapper left-align business-placeholder__wrapper">
                    <div className="business-img-holder xlg" style={{ backgroundImage: `url(${businessDetailsData.logo})` }}>
                    </div>
                    <div className="profile-details">
                      <span className="main-title mt-bg">{businessDetailsData.businessName}</span>
                      <span className="sub-title"><span className="shade-icon__wrapper round"><i className="bi bi-envelope-fill" /></span>{businessDetailsData.businessEmail}</span>
                      <span className="sub-title"><span className="shade-icon__wrapper round"><i className="bi bi-geo-fill" /></span>{businessDetailsData.businessAddressCity}</span>
                    </div>
                    {!editDisabled &&
                      <button className="btn btn-secondary btn-xsmall details-edit-btn" onClick={() => setSsEditDetailsClicked(true)}><i className="bi bi-pencil-fill" />&nbsp;Edit details</button>
                    }
                  </div>
                </div>
              </div>
              <div className="role-details__wrapper">
                <h4 className="role-details__title">Owner Details</h4>
                <div className="role-details-inner__wrapper">
                  <div className="profile-placeholder__wrapper left-align txt-black">
                    <div className="profile-img-holder sm" style={{ backgroundImage: 'url()' }}>
                      {ownerDetailsData.ownerProfileImage}
                    </div>
                    <div className="profile-details txt-black">
                      <span className="main-title">{ownerDetailsData.ownerFirstName} {ownerDetailsData.ownerLastName} (You)<button className="btn btn-info btn-round btn-xsmall"><i className="bi bi-patch-check-fill" /></button></span>
                      <span className="sub-title"><a href="/" className="txt-black">{ownerDetailsData.ownerEmail}</a></span>
                      <span className="sub-title"><a href="/" className="txt-black">{ownerDetailsData.ownerMobile}</a></span>
                    </div>
                  </div>
                  <div className="access-control__wrapper">
                    <div className="access-badge__wrapper partner">
                      <div className="access-icon partner">
                        <img
                          src={ownerBadge}
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="role-details__wrapper">
                <h4 className="role-details__title">Additional Details</h4>
                <div className="role-details-inner__wrapper">
                  <div className="profile-placeholder__wrapper left-align txt-black">
                    <div className="profile-details txt-black">
                      <span className="main-title">Business Description : </span>
                      <span className="sub-title"><a href="/" className="txt-black">{businessDetailsData.businessDescription}</a></span>
                      <hr/>
                      <span className="main-title">{ businessDetailsData.businessRegNumber ?" Business Registration Number :" : " NIC Number : " }<span className="sub-title"><a href="/" className="txt-black">{businessDetailsData.businessRegNumber ? businessDetailsData.businessRegNumber : businessDetailsData.ownerNicNumber}</a></span></span>
                      <hr/>
                      <span className="main-title">Industry : <span className="sub-title"><a href="/" className="txt-black">{businessDetailsData.industryType}</a></span></span>
                      <hr/>
                      <span className="main-title">Business Address : <span className="sub-title"><a href="/" className="txt-black">{businessDetailsData.businessAddressNumber+","+ businessDetailsData.businessAddressStreet+ ","+ 
                      businessDetailsData.businessAddressCity+","+ businessDetailsData.businessAddressDistrict}</a></span></span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          }
          {/* / business details preview - step 1 */}
          {/* business details edit mode - step 2 */}
          {getIsEditDetailsClicked &&
            <div className="card panel content-holder-panels">
              <div className="panel-header">
                <div className="panel-header-content__wrapper">
                  <h4 className="panel-header-title">Business details</h4>
                </div>
              </div>
              <div className="panel-content">
                {/* <form className="wiz-content-form__wrapper"> */}
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper form-element__wbtn right mb-3">
                      <InputTextField
                        name='businessName'
                        label='Business Name'
                        placeholder='Please enter the enterprise name'
                        value={businessDetailsData.businessName}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', { max: 50 }]}
                        disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? false : true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate}
                      />
                    </div>
                  </div>
                  <div className="form-group">

                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3">
                      {/* <InputTextField
                        name='businessEmail'
                        label='Business Email'
                        placeholder='Email address of your enterprise'
                        value={businessDetailsData.businessEmail}
                        setValue={handleBusinessDetailsChange}
                        rules={['required', 'email']}
                        disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? false : false}
                        status="default"
                        validationMessage="This does not look like an email"
                        onBlurValue={onBlurEmail}
                        errorText={emailUnique === false ? 'Email already exists' : ''}
                        forceUpdate={forceUpdate}
                        validator={businessDetailValidator} /> */}
                      {!showEmailOtpField ?
                        <InputTextFieldWithButton
                          name='email'
                          label='Email address'
                          placeholder='someone@someone.com'
                          value={businessDetailsData.businessEmail}
                          setValue={handleBusinessDetailsChange}
                          validator={businessDetailValidator}
                          rules={['required', 'email', 'max:100']}
                          disabled={userDetails ? false : true}
                          status={(emailVerified === "verified") ? "verified" : (emailVerified === "notVerified") ? "notVerified" : ""}
                          validationMessage={(emailVerified === "verified") ? 'verified' : (emailVerified === "notVerified") ? 'Not verified! please use correct type of email address' : ""}
                          buttonLable="Verify"
                          showButton={true}
                          onClickFunc={verifyEmailForYourProfile}
                          buttonDisabled={emailVerified === "verified" ? true : false}
                          forceUpdate={forceUpdate}
                          preValueCheck={true}
                          showRemainingTime={!userDetails && showEmailOtpField}
                          remainingTime={remainingTime}
                        />
                        :
                        <InputTextFieldWithButton
                          name='emailOtp'
                          label='Enter email OTP'
                          placeholder='123456'
                          value={emailOtp.pin}
                          setValue={handleEmailOtpChange}
                          validator={businessDetailValidator}
                          rules={['required', 'numeric', { min: 6 }, { max: 6 }]}
                          disabled={userDetails ? false : true}
                          status={(userDetails?.OwnedEnterprises[0].Enterprise.isEmailVerified) ? "verified" : "notVerified"}
                          validationMessage={(userDetails?.OwnedEnterprises[0].Enterprise.isEmailVerified) ? "Verified!" : "Not verified! please use correct type of Number"}
                          buttonLable="Submit"
                          // showButton={data.msisdnStatus === MsisdnStatusEnums.PENDING}
                          showButton={true}
                          onClickFunc={validateEmailOtp}
                          buttonDisabled={false}
                          forceUpdate={forceUpdate}
                          preValueCheck={true}
                          showRemainingTime={showEmailOtpField}
                          remainingTime={remainingTime} />
                      }
                    </div>
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3">
                      {/* <InputTextField
                        name='billingEmail'
                        label='Billing Email'
                        placeholder='Billing Email Address'
                        value={businessDetailsData.billingEmail}
                        setValue={handleBusinessDetailsChange}
                        rules={['required', 'email']}
                        disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? true : false}
                        status="default"
                        validationMessage="This does not look like an email"
                        onBlurValue={onBlurEmail}
                        errorText={emailUnique === false ? 'Email already exists' : ''}
                        forceUpdate={forceUpdate}
                        validator={businessDetailValidator} /> */}
                      {!showBillingEmailOtpField ?
                        <InputTextFieldWithButton
                          name='billingEmail'
                          label='Billing Email'
                          placeholder='someone@someone.com'
                          value={businessDetailsData.billingEmail}
                          setValue={handleBusinessDetailsChange}
                          validator={businessDetailValidator}
                          rules={['required', 'email', 'max:100']}
                          disabled={userDetails ? false : true}
                          status={(billingEmailVerified === "verified") ? "verified" : (billingEmailVerified === "notVerified") ? "notVerified" : ""}
                          validationMessage={(billingEmailVerified === "verified") ? 'verified' : (billingEmailVerified === "notVerified") ? 'Not verified! please use correct type of email address' : ""}
                          buttonLable="Verify"
                          showButton={true}
                          onClickFunc={verifyBillingEmailForYourProfile}
                          buttonDisabled={billingEmailVerified === "verified" ? true : false}
                          forceUpdate={forceUpdate}
                          preValueCheck={true}
                          showRemainingTime={!userDetails && showBillingEmailOtpField}
                          remainingTime={billingEmailRemainingTime}
                        />
                        :
                        <InputTextFieldWithButton
                          name='billingEmailOtp'
                          label='Enter billing email OTP'
                          placeholder='123456'
                          value={billingEmailOtp.pin}
                          setValue={handleBillingEmailOtpChange}
                          validator={businessDetailValidator}
                          rules={['required', 'numeric', { min: 6 }, { max: 6 }]}
                          disabled={userDetails ? false : true}
                          status={(userDetails?.OwnedEnterprises[0].Enterprise.isBillingEmailVerified) ? "verified" : "notVerified"}
                          validationMessage={(userDetails?.OwnedEnterprises[0].Enterprise.isBillingEmailVerified) ? "Verified!" : "Not verified! please use correct type of Number"}
                          buttonLable="Submit"
                          // showButton={data.msisdnStatus === MsisdnStatusEnums.PENDING}
                          showButton={true}
                          onClickFunc={validateBillingEmailOtp}
                          buttonDisabled={false}
                          forceUpdate={forceUpdate}
                          preValueCheck={true}
                          showRemainingTime={showBillingEmailOtpField}
                          remainingTime={billingEmailRemainingTime} />
                      }
                    </div>
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3">
                      <InputTextArea
                        name='businessDescription'
                        label='Business Description'
                        placeholder='Describe the nature of your business. eg: Entertainment, Education, Retail Business
                          , Food and beverages, Financial etc.'
                        value={businessDetailsData.businessDescription}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required']}
                        disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? true : false}
                        status="default"
                        validationMessage=""
                        rows={3}
                        forceUpdate={forceUpdate}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group__wrapper">
                  {businessDetailsData.businessRegNumber !== "" ?
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3">
                        <InputTextField
                          name='businessRegNumber'
                          label='Business Registration Number'
                          placeholder='Business registration number of your enterprise'
                          value={businessDetailsData.businessRegNumber}
                          setValue={handleBusinessDetailsChange}
                          validator={businessDetailValidator}
                          rules={['required', { min: 1 }, { max: 45 }]}
                          disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? false : true}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate}
                        />
                      </div>
                    </div>
                    :
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3">
                        <InputTextField
                          name='ownerNicNumber'
                          label='Owner NIC Number'
                          placeholder='Owner NIC number'
                          value={businessDetailsData.ownerNicNumber}
                          setValue={handleBusinessDetailsChange}
                          validator={businessDetailValidator}
                          rules={['required', { min: 1 }, { max: 45 }]}
                          disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? false : true}
                          status="default"
                          validationMessage=""
                          forceUpdate={forceUpdate}
                        />
                      </div>
                    </div>
                  }

                  <div className="form-group">
                    <div className="form-element__wrapper mb-3 ">
                      <label htmlFor="businessName" className="form-label">Industry</label>
                      <div className="icon-inside right">
                        <select
                          name="industry"
                          id=""
                          className="form-control"
                          value={businessDetailsData.industryType}
                          onChange={handleBusinessDetailsChange('industryType')}
                          disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? false : true}
                        >
                          {renderIndustryList()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4 className="inner-title">Contact informations</h4>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3">
                      <InputTextField
                        name='businessAddressNumber'
                        label='PO BOX No / Lane'
                        placeholder='PO BOX, House No.'
                        value={businessDetailsData.businessAddressNumber}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', { min: 1 }, { max: 100 }, { regex: /[a-zA-Z0-9.,/\s-]/g }]}
                        disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? true : false}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3">
                      <InputTextField
                        name='businessAddressStreet'
                        label='Street'
                        placeholder='Street'
                        value={businessDetailsData.businessAddressStreet}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', { min: 1 }, { max: 100 }]}
                        disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? true : false}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group__wrapper">
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3">
                      <InputTextField
                        name='businessAddressCity'
                        label='City'
                        placeholder='City'
                        value={businessDetailsData.businessAddressCity}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', { min: 3 }, { max: 100 }]}
                        disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? true : false}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-element__wrapper mb-3 ">
                      <label htmlFor="businessName" className="form-label">District</label>
                      <div className="icon-inside right">
                        <select
                          name="district"
                          id="district"
                          className="form-control"
                          value={businessDetailsData.businessAddressDistrict}
                          onChange={handleBusinessDetailsChange('businessAddressDistrict')}
                          required={true}
                          disabled={(auth?.realmRoles.includes('mp_dialog_agent')) ? true : false}
                        >
                          {renderDistrictList()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {auth?.realmRoles.includes('mp_content_generator') && <>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                        name='responsivePersonName'
                        label="Contact person's name"
                        placeholder="Contact person's name"
                        value={businessDetailsData.responsivePersonName}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', 'alpha_space', { min: 3 }, { max: 25 }]}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate}
                      />
                    </div>
                    <div className="form-group">
                      <InputTextField
                        name='responsivePersonNo'
                        label="Contact person's number"
                        placeholder="Contact person's number"
                        value={businessDetailsData.responsivePersonNumber}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required']}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                  </div>
                </>
                }
                <div className="form-group__wrapper">
                  <div className="form-group">
                    {contactDetailsChanged && (
                      <><div className="alert info">
                        <span className="alert-text__wrapper"><i className="alert-icon bi bi-info-circle-fill" /><span className="alert-text">Supporting document is mandatory if you need to change the enterprise address.</span></span>
                      </div>
                        <FileUpload
                          label={"Upload a support document"}
                          required={true}
                          classes={""}
                          onChangeFile={onChangeSupportDoc}
                          fileList={supportDoc}
                          name={'supportDoc'}
                          rules={[]}
                          onDropFile={onDropSupportDoc}
                          beforeUploadFile={beforeUploadSupportDoc}
                          maxCount={1}
                          multiple={false}
                          fileTypes={'.png,.jpg,.pdf'}
                          validator={businessDetailValidator}
                          uploadSizeError={logoUploadSizeError}
                          maxSize={5} /></>
                    )}
                  </div>
                </div>
                {maDetails.role === 'mp_marketing_admin' && <>
                  <h4 className="inner-title">Professional details</h4>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                        name='expertise'
                        label='Expertise'
                        placeholder='Expertise'
                        value={maDetails.expertise}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', { max: 400 }]}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <InputTextField
                        name='experience'
                        label='Years of experience'
                        placeholder='Years of experience'
                        value={maDetails.experience}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', 'exp_years']}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                  </div>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextArea
                        name='personal_description'
                        label='Description on yourself'
                        placeholder='Brief explanation about your self'
                        rows={3}
                        value={maDetails.personal_description}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required']}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <InputTextField
                        name='specification'
                        label='Specification'
                        placeholder='Specification'
                        value={maDetails.specification}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required']}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                  </div>
                  <h4 className="inner-title">Bank account details</h4>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                        name='account_holder'
                        label='Account Holder Name'
                        placeholder='Account Holder Name'
                        value={maDetails.account_holder}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', 'alpha_space']}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label">Bank
                          <span aria-label="required"></span>
                        </label>
                        <select
                          name="bank"
                          id="bank"
                          className="form-control"
                          value={bankCode || ''}
                          disabled={true}
                        >
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <div className="form-element__wrapper mb-3 ">
                        <label htmlFor="" className="form-label">Branch
                          <span aria-label="required"></span>
                        </label>
                        <select
                          name="branch"
                          id="branch"
                          className="form-control"
                          value={branchCode || ''}
                          disabled={true}
                        >
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <InputTextField
                        name='account_number'
                        label='Account Number'
                        placeholder='Account Number'
                        value={maDetails.account_number}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', 'numeric', { max: 25 }]}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                  </div>
                </>
                }
                {maDetails.role === 'mp_dialog_agent' && <>
                  < h4 className="inner-title">Co-operate Details</h4>
                  <div className="form-group__wrapper">
                    <div className="form-group">
                      <InputTextField
                        name='employee_email'
                        label='Co-Operate Email Address'
                        placeholder='Co-Operate Email Address'
                        value={maDetails.employee_email}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required', 'email']}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate} />
                    </div>
                    <div className="form-group">
                      <InputTextField
                        name='employee_id'
                        label='Employee ID'
                        placeholder='Employee ID'
                        value={maDetails.employee_id}
                        setValue={handleBusinessDetailsChange}
                        validator={businessDetailValidator}
                        rules={['required']}
                        disabled={true}
                        status="default"
                        validationMessage=""
                        forceUpdate={forceUpdate}
                      />
                    </div>
                  </div>
                </>
                }
                <div className="panel-bottom-btn__wrapper">
                  {businessDetailsChanged && (
                    <><button className="btn btn-secondary" onClick={onChangeClose}>Cancel</button><button
                      className="btn btn-primary"
                      disabled={!businessDetailsData.businessDescription || !businessDetailsData.businessAddressCity
                        || !businessDetailsData.businessAddressDistrict || !businessDetailsData.businessAddressNumber
                        || !businessDetailsData.businessAddressStreet || (contactDetailsChanged && supportDoc.length === 0) || !businessDetailValidator.allValid()
                      }
                      onClick={(e) => onEditDetailsSave(e)}>
                      Save &amp; Continue
                    </button></>
                  )}
                </div>

                {enterpriseDetailsUpdateStatus === "Pending" &&
                  <div className="alert warning">
                    <span className="alert-text__wrapper"><i className="alert-icon bi bi-exclamation-circle-fill" /><span className="alert-text">Enterpsrise details changes are under admin approval process!</span></span>
                  </div>
                }
                {/* </form> */}
              </div>
            </div>
          }
          {/* / business details edit mode - step 2 */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default BusinessDetails;

